




































































































































































































































































































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { Perfil, Permissao } from '@/models'
import { PermissaoDeRecurso } from '@/models/Recurso'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import {
	FindPerfilUseCase,
	CreatePerfilUseCase,
	UpdatePerfilUseCase,
	FindRelatorioUseCase,
	SaveRelatorioUseCase,
	FindDashboardsUseCase,
	SaveDashboardsUseCase,
} from '@/usecases'
import { obrigatorio, tempoParaLogoffInvalido, tempoLimiteDeInatividadeInvalido } from '@/shareds/regras-de-form'
import {
	listaDeRecursos,
	listaDeOperacoes,
	montarOperacaoDeRecurso,
	gruposDeRegras,
} from '@/shareds/permissoes-shareds'
import { GrupoDeRegrasDeNegocio, PermissaoDeRegraDeNegocio } from '../../../models/RegraDeNegocio'
import { nextTick } from '@/shareds/utils'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { ViewPerfil, ViewRelatorio, ViewRelatorioCheck } from '@/models/views/ViewRelatorio'
import axios, { CancelTokenSource } from 'axios'
import ListaDeGruposEconomicos from '@/components/usuario/ListaDeGruposEconomicos.vue'
import { CapaDashboard, CapaDashboardPerfil, ViewPerfilDashboard } from '@/models/views/CapaDashboard'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { mask } from 'vue-the-mask'


@Component({
	components: {
		EdicaoGenerica,
		ListaDeGruposEconomicos,
		CampoDePercentual,
	},

	directives: {
		mask,
	},
})
export default class EdicaoDePerfil extends Vue {
	@Ref() edicaoGenerica!: EdicaoGenerica<Permissao>
	@Prop({ type: String }) id!: string
	@Prop({ type: Boolean }) novo!: boolean

	obrigatorio = obrigatorio
	tempoParaLogoffInvalido = tempoParaLogoffInvalido
	tempoLimiteDeInatividadeInvalido = tempoLimiteDeInatividadeInvalido
	listaDeOperacoes = listaDeOperacoes
	montarOperacaoDeRecurso = montarOperacaoDeRecurso

	createPerfilUseCase = new CreatePerfilUseCase()
	updatePerfilUseCase = new UpdatePerfilUseCase()
	findUseCase = new FindPerfilUseCase()
	findRelatorioUseCase = new FindRelatorioUseCase()
	saveRelatorioUseCase = new SaveRelatorioUseCase()
	findDashboardUseCase = new FindDashboardsUseCase()
	saveDashboardUseCase = new SaveDashboardsUseCase()

	perfil: Perfil | null = null
	versaoAnterior: Perfil | null = null
	salvo = false
	carregando = true
	salvando = false

	erro: null | Error = null
	viewPerfis: ViewPerfil[] = []
	viewRelatoriosSelect: ViewRelatorio[] = []
	viewRelatorios: ViewRelatorio[] = []
	cancelToken: CancelTokenSource | null = null
	mostra: boolean | false = false
	relatorioAdicionado: string | null = null
	relatoriosSelect: ViewRelatorio[] | [] = []
	relatoriosList: ViewRelatorioCheck[] | [] = []
	teste= false

	mostraDash: boolean | false = false
	viewCapaPerfis: ViewPerfilDashboard[] = []
	viewCapaPerfilSalvar: CapaDashboard[] = []
	capaDashboardsSelect: CapaDashboard[] = []
	capaDashboards: CapaDashboard[] = []
	dashboardAdicionado: string | null = null
	dashboardSelect: CapaDashboard[] | [] = []
	dashboardList: CapaDashboard[] | [] = []
	mostraConfiguracaoDeDesconto = false
	mostraConfiguracaoDeDescontoPorItem = false
	formValid = false
	percentualMaximoDesconto = 0
	percentualMaximoDescontoPorItem = 0

	created() {
		if (this.perfil) {
			this.percentualMaximoDesconto = this.perfil.percentualMaximoDesconto || 0
			this.percentualMaximoDescontoPorItem = this.perfil.percentualMaximoDescontoPorItem || 0
		}
		this.carregar()
	}

	async carregar() {
		try {
			this.carregando = true

			this.perfil = this.novo
				? criarPerfil()
				: await this.findUseCase.findById(this.id)
			nextTick().then(() => {
				this.versaoAnterior = JSON.parse(JSON.stringify(this.perfil))
			})

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.viewRelatorios = await this.findRelatorioUseCase.listarViews(axiosConfig)

			this.viewPerfis = this.novo ? [] : await this.findRelatorioUseCase.listarViewPorPerfil(this.id, axiosConfig)

			this.viewRelatoriosSelect = await this.findRelatorioUseCase.listarViews(axiosConfig)

			
			this.capaDashboards = await this.findDashboardUseCase.listarDashs(axiosConfig)
			
			this.viewCapaPerfis = this.novo ? [] : await this.findDashboardUseCase.listarDashPorPerfil(this.id, axiosConfig)
			
			this.capaDashboardsSelect = await this.findDashboardUseCase.listarDashs(axiosConfig)

		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async salvar() {
		if (!this.perfil) {
			AlertModule.setError('Perfil é obrigatório')
			return
		}

		if (this.perfil.gruposEconomicos.length === 0) {
			AlertModule.setError('Informe ao menos um grupo econômico')
			return
		}

		try {
			this.salvando = true
			const perfil = !this.perfil.id
				? await this.createPerfilUseCase.create(this.perfil)
				: await this.updatePerfilUseCase.update(this.perfil)
			if (UserLoginStore.perfil && perfil.id === UserLoginStore.perfil.id) {
				UserLoginStore.setPerfil(perfil)
			}
			this.versaoAnterior = JSON.parse(JSON.stringify(this.perfil))

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.viewPerfis.map(viewPerfil => viewPerfil.perfilId = perfil.id)

			await this.saveRelatorioUseCase.salvarViewPerfis(this.viewPerfis, axiosConfig)

			const capaDashboardPerfis = this.viewCapaPerfis.map(view => ({
				id: view.id,
				nomeDaCapa: view.nomeDaCapa,
				perfil: perfil,
			}));
			
			await this.saveDashboardUseCase.salvarDashsPerfis(
				capaDashboardPerfis.length === 0 
					? [] 
					: capaDashboardPerfis,
				axiosConfig,
			);

			AlertModule.setSuccess('Perfil salvo com sucesso')
			this.salvo = true
			this.$router.push({ name: 'Perfis' })
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	
	formatarTempo(horaInicial: string): string {
		const hours = horaInicial.substr(0, 2) || '00'
		const minutes = horaInicial.substr(2, 2) || '00'
		const seconds = horaInicial.substr(4, 2) || '00'
		return `${hours}:${minutes}:${seconds}`
	}

	@Watch('mostra')
	onChangeMostra() {
		this.viewPerfis.forEach(viewPerfil => {
			const index = this.viewRelatoriosSelect.findIndex(view => view.id === viewPerfil.viewId)
			if (index >= 0) {
				this.viewRelatoriosSelect = this.viewRelatoriosSelect.filter((_, i) => i !== index);
			}
		});
		
		this.relatoriosSelect = [...this.viewRelatoriosSelect];
	}

	@Watch('viewPerfis')
	onChangeViewPerfis() {
		this.relatoriosList = this.viewPerfis.map(viewPerfil => {
			const viewPerfilFiltrado = this.viewRelatorios.filter(viewRelatorio => viewRelatorio.id === viewPerfil.viewId)[0]
			
			return {
				id: viewPerfilFiltrado.id,
				nomeRelatorio: viewPerfilFiltrado.nomeRelatorio,
			} as ViewRelatorioCheck
		})
	}

	@Watch('mostraDash')
	onChangeMostraDash() {
		this.viewCapaPerfis.forEach(viewCapaPerfil => {
			const index = this.capaDashboardsSelect.findIndex(capa => capa.id === viewCapaPerfil.id);
			if (index >= 0) {
				this.capaDashboardsSelect.splice(index, 1);
			}
		});
		this.dashboardSelect = this.capaDashboardsSelect;
	}

	@Watch('viewCapaPerfis')
	onChangeViewCapaPerfis() {
		this.dashboardList = this.viewCapaPerfis.map(viewCapas => {
			const viewCapaPerfil = this.capaDashboards.filter(viewCapa => viewCapa.id === viewCapas.id)[0]
			return {
				id: viewCapaPerfil.id,
				nomeDaCapa: viewCapaPerfil.nomeDaCapa,
				perfil: this.perfil,
			} as CapaDashboard
		})
	}
	adicionarDash() {
		const dashNovo = this.capaDashboards.find(capa => capa.id === this.dashboardAdicionado)
		if (dashNovo) {
			const capaDashboard = {
				id: dashNovo.id,
				nomeDaCapa: dashNovo.nomeDaCapa,
				perfil: this.perfil,
			} as unknown as CapaDashboardPerfil
			
			this.viewCapaPerfis.push(capaDashboard)
		}
		this.mostraDash = false
		this.dashboardAdicionado = null
	}

	removerDashBoard(dashboard: CapaDashboard) {
		const index =  this.viewCapaPerfis.findIndex(viewCapaPerfil => viewCapaPerfil.id === dashboard.id)
		this.viewCapaPerfis.splice(index, 1)
	}

	adicionarRelatorio() { 
		const relatorioNovo = this.viewRelatorios.find(view => view.id === this.relatorioAdicionado)

		if (relatorioNovo) {
			const viewPerfil = {
				id: null,
				perfilId: this.id,
				viewId: relatorioNovo.id,
			} as unknown as ViewPerfil

			this.viewPerfis.push(viewPerfil)
		}
		
		this.mostra = false
		this.relatorioAdicionado = null
	}

	setPermissaoPerfil(chave: string, valor: boolean) {
		if (!this.perfil) return

		valor
			? this.perfil.permissoes.push(chave)
			: this.perfil.permissoes = this.perfil.permissoes.filter(
				permissao => permissao !== chave,
			)
	}

	get permissoesDeRecurso(): PermissaoDeRecurso[] {
		const temAcessoIrrestrito = UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')

		const recursosDeAdministrador = [
			'marcas',
			'contas',
			'grupos-economicos',
		]

		const listaDeRecursosFiltrado = temAcessoIrrestrito ?
			listaDeRecursos
			:
			listaDeRecursos.filter(recurso => !recursosDeAdministrador.includes(recurso.chave))

		const listaFormatada = listaDeRecursosFiltrado.map<PermissaoDeRecurso>(recurso => ({
			recurso,
			operacoes: listaDeOperacoes.reduce(
				(operacoes, operacao) => ({
					...operacoes,
					[operacao]:
						this.perfil?.permissoes.some(
							permissao => permissao === montarOperacaoDeRecurso(operacao, recurso.chave),
						),
				}),
				{
					ler: false,
					criar: false,
					alterar: false,
					deletar: false,
				},
			),
		}))

		const usuarioLogadoTemAcessoIrrestrito = UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')

		const permissoesDoPerfilLogado = UserLoginStore.perfil?.permissoes.filter(permissao => permissao.includes('ler/')).map(permissao => permissao.replace('ler/', ''))

		if (!usuarioLogadoTemAcessoIrrestrito) {
			return listaFormatada.filter(permissao => permissoesDoPerfilLogado?.includes(permissao.recurso.chave))
		}

		return listaFormatada
	}

	get gruposDeRegras(): GrupoDeRegrasDeNegocio[] {
		const { perfil } = this
		if (!perfil) return []
		const gruposMap = gruposDeRegras.map<GrupoDeRegrasDeNegocio>(grupo => ({
			nome: grupo.nome,
			regras: grupo.regras.map<PermissaoDeRegraDeNegocio>(regra => ({
				regra,
				valor: perfil.permissoes.some(
					permissao => permissao === regra.chave,
				),
			})),
		}))

		const usuarioLogadoTemAcessoIrrestrito = UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')

		gruposMap.forEach(grupo => {
			if (grupo.nome === 'Operações Gerais') {
				const regra = grupo.regras.find(permissao => permissao.regra.chave === 'acesso-irrestrito' && !usuarioLogadoTemAcessoIrrestrito)

				if (regra && !regra.valor) {
					const regraIndex = grupo.regras.findIndex(permissao => permissao.regra.chave === 'acesso-irrestrito' && !usuarioLogadoTemAcessoIrrestrito)

					grupo.regras.splice(regraIndex, 1)
				}
			}
		})


		return gruposMap
	}

	get podeEditar() {
		return this.edicaoGenerica && this.edicaoGenerica.podeEditar
	}

	async beforeRouteLeave(to, from, next) {
		if(!this.salvo) {
			this.salvo = false
			next(!await this.edicaoGenerica.validarTransicao())
		}else {
			next()
		}
	}

	removerRelatorio(relatorio: ViewRelatorioCheck) {
		const index = this.viewPerfis.findIndex(viewPerfil => viewPerfil.viewId === relatorio.id)

		this.viewPerfis.splice(index, 1)
	}

	async editarPercentualDesconto() {
		this.mostraConfiguracaoDeDesconto = !this.mostraConfiguracaoDeDesconto
		if (this.perfil) {
			this.percentualMaximoDesconto = this.perfil.percentualMaximoDesconto
		}
	}

	async editarPercentualDescontoPorItem() {
		this.mostraConfiguracaoDeDescontoPorItem = !this.mostraConfiguracaoDeDescontoPorItem
		if (this.perfil) {
			this.percentualMaximoDescontoPorItem = this.perfil.percentualMaximoDescontoPorItem
		}
	}

	get percentualEValido() {
		return () => {
			if (
				this.percentualMaximoDesconto > 99.99
			)
				return 'Percentual não pode ser maior que 99.99%'
			return true
		}
	}

	get percentualPorItemEValido() {
		return () => {
			if (
				this.percentualMaximoDescontoPorItem > 99.99
			)
				return 'Percentual não pode ser maior que 99.99%'
			return true
		}
	}

	atualizarPercentual() {
		if (!this.perfil) return

		this.perfil.percentualMaximoDesconto = this.percentualMaximoDesconto

		this.mostraConfiguracaoDeDesconto = false
	}

	atualizarPercentualPorItem() {
		if (!this.perfil) return

		this.perfil.percentualMaximoDescontoPorItem = this.percentualMaximoDescontoPorItem

		this.mostraConfiguracaoDeDescontoPorItem = false
	}
}

function criarPerfil(): Perfil {
	return {
		id: '',
		nome: '',
		permissoes: [],
		gruposEconomicos: [],
		percentualMaximoDesconto: 0,
		tempoParaLogoff: '00:00:00',
		percentualMaximoDescontoPorItem: 0,
		tempoLimiteDeInatividade: '00:00:00',
	}
}
